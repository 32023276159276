@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  .bg-split {
    --tw-gradient-stops: var(--tw-gradient-from) 0%, var(--tw-gradient-from) 50%, var(--tw-gradient-to) 50%, var(--tw-gradient-to);
    @apply bg-gradient-to-b;
  }

  .bg-gradient-to-t-half {
    --tw-gradient-stops: var(--tw-gradient-from) 0%, var(--tw-gradient-to) 50%, var(--tw-gradient-to);
    @apply bg-gradient-to-t;
  }

  .bg-current-10 {
    background-color: color-mix(in srgb, currentColor 10%, transparent 90%);
  }

  .default-outline {
    @apply outline outline-offset-2 outline-1 outline-white rounded ring ring-sky;
  }

  /* Used to ensure carousel items behave well with a gap of 24px and a 16px margin on mobile */
  .carousel-item-wrapper {
    @apply h-full px-4 lg:px-3 pb-2;
  }

  .video-carousel-card {
    @apply opacity-100 transition-all duration-300 origin-center scale-[90%]  lg:scale-[85%];
  }

  .video-carousel-card .image {
    @apply after:content-[''] after:bg-plum-64 after:opacity-40 after:inset-0 after:absolute after:transition-all after:duration-300;
  }
  .video-carousel-card:hover .image {
    @apply after:opacity-0;
  }

  .slick-center .video-carousel-card .hidden-not-center {
    @apply opacity-100;
  }

  .video-carousel-card .hidden-not-center {
    @apply opacity-0 transition-opacity duration-300;
  }
  .video-carousel-card:hover .hidden-not-center {
    @apply opacity-100;
  }

  .slick-center .video-carousel-card {
    @apply opacity-100 scale-100 z-10;
  }

  .slick-center .video-carousel-card .image {
    @apply after:opacity-0;
  }

  .marquee-mask {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%);
  }


}

@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";
@import "../node_modules/nprogress/nprogress.css";
@import "./slick-flex.css";
@import "./react-player.css";
@import "./hubspot.css";

.h-screen-new {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}


body {
  font-family: "Inter";
}

#__next {
  display: flex;
  flex: 1;
}

/* Key take-aways from this article: */


.prose-jd.prose h2 {
  border-bottom: 1px solid rgba(30, 25, 40, 0.16);
  padding-bottom: 1em;
}

.question.prose>ul>li>p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@font-face {
  font-family: "Empathy";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Empathy-Regular.woff2") format("woff2"),
    url("/fonts/Empathy-Regular.woff") format("woff");
}